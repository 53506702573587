<template>
  <div class="social">
    <div class="tw-container border border-1 border-dark p-3">
      <!-- 標題 -->
      <p class="tw-border-start fw-bolder tw-text-size20">社群平台</p>
      <hr />
      <!-- 內容 -->
      <div
        class="row p-3 linkAccounts-area"
        style="height: calc(100vh - 143px); overflow-y: scroll"
      >
        <!-- FB -->
        <div class="col-lg">
          <!-- 社團 -->
          <div class="link-box fb-box">
            <a
              href="https://youtu.be/BcxcnXobUcE?si=5wYjC7ie8rAypGBT"
              target="_blank"
            >
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
                alt=""
              />
            </a>
            <img
              class="social-icon-img"
              src="@/assets/icon/facebook-3.png"
              alt=""
            />
            <span class="text-light tw-text-size18 text-margin-rwd fw-bolder"
              >FB社團</span
            >
            <div>
              <AreaLoading
                v-if="fbGroup.loading"
                class="grid-line"
              ></AreaLoading>
              <div class="grid-line" v-else-if="!canLinkFb">
                <router-link
                  class="tw-btn tw-btn-light text-primary me-2 \"
                  to="/user/linkAccounts"
                  >立即前往綁定FB</router-link
                >
              </div>

              <span v-else class="text-light tw-text-size18">
                <div v-if="!linkFbGroup.name" class="grid-line">
                  <button
                    class="tw-btn tw-btn-light text-primary me-2"
                    @click="showModal('connectGroup')"
                  >
                    綁定社團
                  </button>
                </div>
                <div class="d-flex align-item-center grid-line" v-else>
                  <button
                    class="tw-btn tw-btn-light text-danger me-3"
                    @click="unLinkGroup"
                  >
                    取消綁定
                  </button>
                  <p
                    class="d-inline-block fw-bolder text-break pe-4"
                    style="min-width: 100px"
                  >
                    {{ linkFbGroup.name }}
                  </p>
                </div>
              </span>
            </div>
          </div>
          <!-- 粉絲專業 -->
          <div class="link-box fb-box">
            <a
              href="https://youtu.be/NUT8FGArd6U?si=Pt1yR9VMdOsVp13S"
              target="_blank"
            >
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
                alt=""
              />
            </a>
            <img
              class="social-icon-img"
              src="@/assets/icon/facebook-3.png"
              alt=""
            />
            <span class="text-light tw-text-size18 fw-bolder text-margin-rwd"
              >粉絲專業</span
            >
            <AreaLoading v-if="fbPage.loading" class="grid-line"></AreaLoading>
            <div class="grid-line" v-else-if="!canLinkFb">
              <router-link
                class="tw-btn tw-btn-light text-primary"
                to="/user/linkAccounts"
                >立即前往綁定FB</router-link
              >
            </div>

            <span v-else class="text-light tw-text-size18">
              <div v-if="!linkFbPage.name" class="grid-line">
                <button
                  class="tw-btn tw-btn-light text-primary me-2"
                  @click="showModal('connectFans')"
                >
                  綁定粉專
                </button>
              </div>
              <div class="d-flex align-item-center grid-line" v-else>
                <button
                  class="tw-btn tw-btn-light text-danger me-3"
                  @click="unLinkPage"
                >
                  取消綁定
                </button>
                <p
                  class="d-inline-block fw-bolder text-break pe-4"
                  style="min-width: 100px"
                >
                  {{ linkFbPage.name }}
                </p>
              </div>
            </span>
          </div>
        </div>
        <!-- Line -->
        <div class="col-lg">
          <!-- 群組Notify -->
          <div class="link-box line-box">
            <img
              class="info-icon"
              src="@/assets/icon/interrogation.png"
              alt=""
              @click="showModal('lineNotify')"
            />
            <img
              class="social-icon-img"
              src="@/assets/icon/linenotify.png"
              alt=""
            />
            <span class="text-light tw-text-size18 fw-bolder text-margin-rwd"
              >群組Notify</span
            >
            <AreaLoading
              v-if="storeLineNotifyLinks.loading"
              class="pt-1 grid-line"
            ></AreaLoading>
            <div class="pt-1" v-else>
              <div v-if="canLinkLine">
                <span
                  class="
                    fw-bolder
                    text-danger
                    grid-line
                    d-inline-block
                    text-break
                  "
                  style="min-width: 100px"
                  v-if="storeLineNotifyLinks.data.length === 0"
                >
                  目前尚無綁定LINE Notify!</span
                >
                <div v-else class="grid-line">
                  <p
                    v-for="item in storeLineNotifyLinks.data"
                    :key="item.id"
                    class="text-light mb-3 d-flex align-item-center"
                  >
                    <button
                      class="tw-btn tw-btn-light text-danger me-3"
                      @click="unLinkLineNotify(item.id)"
                    >
                      取消綁定
                    </button>
                    <span
                      class="d-inline-block fw-bolder text-break pe-4 mt-1"
                      style="min-width: 100px"
                      >{{ item.groupName }}</span
                    >
                  </p>
                </div>
              </div>
              <router-link
                v-else
                class="tw-btn tw-btn-light text-success me-2"
                to="/user/linkAccounts"
                >立即前往綁定 Line</router-link
              >
            </div>
          </div>
          <!-- Line bot -->
          <div class="link-box line-box">
            <img
              class="info-icon"
              src="@/assets/icon/interrogation.png"
              alt=""
              @click="showModal('lineBot')"
            />
            <img
              class="social-icon-img"
              src="@/assets/icon/LINE_BOT.jpg"
              alt=""
            />
            <span class="text-light tw-text-size18 fw-bolder text-margin-rwd"
              >Line Bot</span
            >
            <AreaLoading
              v-if="lineBotData.loading"
              class="pt-3 grid-line"
            ></AreaLoading>
            <div class="pt-1" v-else>
              <div v-if="canLinkLine">
                <span
                  class="
                    fw-bolder
                    text-danger
                    grid-line
                    d-inline-block
                    text-break
                  "
                  style="min-width: 100px"
                  v-if="lineBotData.data.length === 0"
                >
                  目前尚無綁定LINE Bot!</span
                >
                <div class="grid-line" v-else>
                  <p
                    v-for="item in lineBotData.data"
                    :key="item.id"
                    class="text-light mb-3 d-flex align-item-center"
                  >
                    <button
                      class="tw-btn tw-btn-light text-danger me-3"
                      @click="unLinkLineBot(item.id)"
                    >
                      取消綁定
                    </button>
                    <span
                      class="d-inline-block fw-bolder text-break pe-4 mt-1"
                      style="min-width: 100px"
                      >{{ item.summary.groupName }}</span
                    >
                  </p>
                </div>
              </div>
              <router-link
                v-else
                class="tw-btn tw-btn-light text-success me-2"
                to="/user/linkAccounts"
                >立即前往綁定 Line</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 綁定社團 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="connectGroupModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">綁定社團</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AreaLoading v-if="fbGroup.loading"></AreaLoading>
          <p
            v-else-if="fbGroup.data.length === 0"
            class="fw-bolder text-danger"
          >
            尚未有任何社團!!
          </p>
          <div v-for="data in fbGroup.data" :key="data.groupId" v-else>
            <div class="mb-2" v-if="data.result">
              <span class="fw-bolder">
                {{ data.groupName }}
                <span class="text-danger">
                  已和 {{ data.result.storeName }} 賣場綁定
                </span>
              </span>
            </div>
            <div class="form-check p-0" v-else>
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                :value="data.groupId"
                :id="data.groupId"
                name="groupLink"
                v-model="selectItem"
              />
              <label class="form-check-label" :for="data.groupId">
                {{ data.groupName }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="linkGroup"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 綁定粉專 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="connectFansModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">綁定粉專</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AreaLoading v-if="fbPage.loading"></AreaLoading>
          <p v-else-if="fbPage.data.length === 0" class="fw-bolder text-danger">
            尚未有任何粉專!!
          </p>
          <div v-for="data in fbPage.data" :key="data.pageId" v-else>
            <div class="mb-2" v-if="data.result">
              <span class="fw-bolder">
                {{ data.pageName }}
                <span class="text-danger">
                  已和{{ data.result.storeName }} 賣場綁定
                </span>
              </span>
            </div>
            <div class="form-check p-0" v-else>
              <input
                class="form-check-input m-0 me-2"
                type="radio"
                :value="data.pageId"
                :id="data.pageId"
                name="pageLink"
                v-model="selectItem"
              />
              <label class="form-check-label" :for="data.pageId">
                {{ data.pageName }}
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="linkPage">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- line notify modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="lineNotifyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">綁定LINE Notify
            <a
              href="https://youtu.be/tnagmnhxJLM?si=pq2xR2kCuJmRUTR1"
              target="_blank"
            >
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
                alt=""
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-end">
            <button
              type="button"
              class="tw-btn tw-btn-success mb-2"
              @click="linkLineNotify"
            >
              下一步
            </button>
          </div>
          <img
            src="@/assets/images/teaching-images/line-notify.png"
            style="width: 100%"
            alt="lineNotify教學圖"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="linkLineNotify"
          >
            下一步
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- line bot modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="lineBotModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">綁定LINE Bot
            <a
              href="https://youtu.be/tnagmnhxJLM?si=pq2xR2kCuJmRUTR1"
              target="_blank"
            >
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
                alt=""
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img
            :src="linebotTeachingImg"
            style="width: 100%"
            alt="linebot教學圖"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            了解
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// api
import { parseToken, sortoutParseTokenResult } from '../../../methods/API/parseToken'
// mixin
import { Permission } from '@/methods/mixins/permission';

export default {
  data() {
    return {
      // modal
      connectGroupModal: {},
      connectFansModal: {},
      lineBotModal: {},
      lineNotifyModal: {},
      // data
      serverToken: '',
      storeId: 0,
      canLinkFb: false,
      canLinkLine: false,
      // LineNotify
      storeLineNotifyLinks: {
        loading: false,
        data: []
      },
      // lineBotData
      lineBotData: {
        loading: false,
        data: []
      },
      // FB 群組資料
      fbGroup: {
        loading: false,
        data: []
      },
      linkFbGroup: {},
      // FB 粉專資料
      fbPage: {
        loading: false,
        data: []
      },
      linkFbPage: {},
      // 選擇內容
      selectItem: ''
    }
  },
  mixins: [Permission],
  created() {
    this.initialization()
    this.getUserInfo()
    this.getLineNotify()
    this.getLineBot()
  },
  mounted() {
    this.createModals([
      'connectGroupModal','connectFansModal','lineBotModal','lineNotifyModal',
    ])
  },
  computed: {
    // line bot 教學圖
    linebotTeachingImg() {
      let imgUrl;
      switch (process.env.VUE_APP_MERCHINESTATUS) {
        case "develop":
          imgUrl = require("@/assets/images/teaching-images/line-bot-develop.png");
          break;
        case "test":
          imgUrl = require("@/assets/images/teaching-images/line-bot-test.png");
          break;
        case "online":
          imgUrl = require("@/assets/images/teaching-images/line-bot-online.png");
          break;
      }
      return imgUrl;
    },
  },
  methods:{
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 為了在綁定 line notify 之後可以順利回來用的
      localStorage.setItem('currentStoreId', this.storeId)
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'connectGroup') {
        this.connectGroupModal.show()
      } else if (status === 'connectFans') {
        this.connectFansModal.show()
      } else if (status === 'lineBot') {
        this.lineBotModal.show()
      } else if (status === 'lineNotify') {
        this.lineNotifyModal.show()
      }
    },
    // 取得使用者資訊
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.getPhysicalUserInfo(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得實體使用者資訊
    getPhysicalUserInfo(userInfo) {
      const vm = this
      const getPhysicalUserInfo = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: userInfo.ids,
          methods: '{getUserLineLink,getUserFacebookLink}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfo,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const physicalUserInfo = res.data[0].objects[0]
            if (physicalUserInfo.userLineLink && physicalUserInfo.userLineLink.appUserId) vm.canLinkLine = true
            if (physicalUserInfo.userFacebookLink && physicalUserInfo.userFacebookLink.appUserId) {
              vm.canLinkFb = true
              vm.checkRelationsOfGroup()
              vm.checkRelationsOfPage()
            }
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 取得 FB 群組 
    checkRelationsOfGroup() {
      const vm = this
      const checkRelationsOfGroupApi = `${process.env.VUE_APP_API}/apps/fb/checkRelationsOfGroup`;
      const header = {
        authorization: this.serverToken
      }
      this.fbGroup = {
        loading: true,
        data: []
      }
      this.linkFbGroup = {}
      $.ajax({
        type: 'GET',
        async: true,
        url: checkRelationsOfGroupApi,
        headers: header,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.fbGroup = {
              loading: false,
              data: res.data
            }
            vm.fbGroup.data.forEach(item => {
              for (let i = 0; i < item.storeFbGroupLinks.length; i++) {
                if (!item.storeFbGroupLinks[i].deleted) {
                  if (item.storeFbGroupLinks[i].storeId === parseInt(vm.storeId)) {
                    vm.linkFbGroup = {
                      name: item.storeFbGroupLinks[i].name,
                    }
                    item.result = {
                      link: true,
                      linkStatus: "currentStore",
                      storeName: item.storeFbGroupLinks[i].store.name
                    }
                  } else {
                    item.result = {
                      link: true,
                      linkStatus: "otherStore",
                      storeName: item.storeFbGroupLinks[i].store.name
                    }
                  }
                }
              }
            });
          } 
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
          if (err.responseJSON.code === '400-14') {
            vm.fbGroup = {
              loading: false,
              data: ['帳號無綁定FB']
            }
          } else {
            vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          }
        }
      })

    },
    // 取得 FB 粉專 
    checkRelationsOfPage() {
      const vm = this     
      const checkRelationsOfPageApi = `${process.env.VUE_APP_API}/apps/fb/checkRelationsOfPage`;
      const header = {
        authorization: this.serverToken
      }
      this.fbPage = {
        loading: true,
        data: []
      }
      this.linkFbPage = {}
      $.ajax({
        type: 'GET',
        async: true,
        url: checkRelationsOfPageApi,
        headers: header,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.fbPage = {
              loading: false,
              data: res.data
            }
            vm.fbPage.data.forEach(item => {
              for (let i = 0; i < item.storeFbPageLinks.length; i++) {
                if (!item.storeFbPageLinks[i].deleted) {
                  if (item.storeFbPageLinks[i].storeId === parseInt(vm.storeId)) {
                    vm.linkFbPage = {
                      name: item.storeFbPageLinks[i].name,
                    }
                    item.result = {
                      link: true,
                      linkStatus: "currentStore",
                      storeName: item.storeFbPageLinks[i].store.name
                    }
                  } else {
                    item.result = {
                      link: true,
                      linkStatus: "otherStore",
                      storeName: item.storeFbPageLinks[i].store.name
                    }
                  }
                }
              }
            });
          } 
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
          if (err.responseJSON.code === '400-14') {
            vm.fbPage = {
              loading: false,
              data: ['帳號無綁定FB']
            }
          } else {
            vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          }
        }
      })
    },
    // 取的 Line Notify
    getLineNotify() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreLineNotifyLinks}'
        }
      ]
      this.storeLineNotifyLinks = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            // 賣場資訊 
            vm.storeInfo = storeInfo
            // 紀錄 line notify
            vm.storeLineNotifyLinks = {
              loading: false,
              data: storeInfo.storeLineNotifyLinks
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得 Line Bot
    getLineBot() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreLineGroupLinks{getSummary}}'
        }
      ]
      this.lineBotData = {
        loading: true,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            // 賣場資訊 
            vm.storeInfo = storeInfo
            storeInfo.storeLineGroupLinks.forEach((item) => {
              if (!item.deleted) vm.lineBotData.data.push(item);
            });
            vm.lineBotData.loading = false
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 關聯社團
    linkGroup() {
      this.$methods.switchLoading('show')
      const vm = this
      const linkStoreToGroupApi = `${process.env.VUE_APP_API}/apps/fb/linkStoreToGroup`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append("storeId", this.storeId)
      data.append("groupId", this.selectItem)
      $.ajax({
        type: 'POST',
        async: true,
        url: linkStoreToGroupApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.checkRelationsOfGroup()
            vm.connectGroupModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 關聯粉專
    linkPage() {
      this.$methods.switchLoading('show')
      const vm = this
      const linkStoreToPageApi = `${process.env.VUE_APP_API}/apps/fb/linkStoreToPage`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append("storeId", this.storeId)
      data.append("pageId", this.selectItem)
      $.ajax({
        type: 'POST',
        async: true,
        url: linkStoreToPageApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.checkRelationsOfPage()
            vm.connectFansModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 取消關聯社團
    unLinkGroup() {
      this.$methods.switchLoading('show')
      const vm = this
      const unlinkGroupApi = `${process.env.VUE_APP_API}/apps/fb/unlinkStoreToGroup`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData();
      data.append("storeId", this.storeId);
      $.ajax({
        type: 'PUT',
        async: true,
        url: unlinkGroupApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.checkRelationsOfGroup()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide') }
      })
    },
    // 取消關聯粉專
    unLinkPage() {
      this.$methods.switchLoading('show')
      const vm = this
      const unlinkPageApi = `${process.env.VUE_APP_API}/apps/fb/unlinkStoreToPage`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData();
      data.append("storeId", this.storeId);
      $.ajax({
        type: 'PUT',
        async: true,
        url: unlinkPageApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.checkRelationsOfPage()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 關聯 line notify
    linkLineNotify() {
      const serverToken = this.serverToken;
      const notifyRequestAuthUrl = `https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=${process.env.VUE_APP_NOTIFY_ID}&redirect_uri=${process.env.VUE_APP_NOTIFY_CALLBACK}&scope=notify&state=store:${this.storeId} ${serverToken}`;
      window.location = notifyRequestAuthUrl;
    },
    // 取消關聯 Line Notify
    unLinkLineNotify(Id) {
      this.$methods.switchLoading('show')
      const vm = this
      const unLinkLineNotifyApi = `${process.env.VUE_APP_API}/apps/line/revokeNotify`;
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append("revokeUserNotify", false)
      data.append("storeLineNotifyLinkIds", Id)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: unLinkLineNotifyApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getLineNotify()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 取消關聯 Line Bot
    unLinkLineBot(id) {
      this.$methods.switchLoading('show')
      const vm = this
      const unLinkLineBotApi = `${process.env.VUE_APP_API}/apps/line/leaveGroup`;
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData();
      data.append("linkIds", id);
      $.ajax({
        type: 'PUT',
        async: true,
        url: unLinkLineBotApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getLineBot()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    }
  }
}
</script>